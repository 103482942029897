import store from "../store/index";
import router from "../routes/router";

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.withCredentials = true;

// Create axios instance
const service = window.axios.create({
  baseURL: process.env.VUE_APP_API_URL
  // timeout: 10000 // Request timeout
});

// Request interceptors
service.interceptors.request.use(
  config => {
    // const token = isLogged();
    // if (token) {
    //   config.headers['Authorization'] = 'Bearer ' + isLogged(); // Set JWT token
    // }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
    // if (response.headers.authorization) {
    //   setLogged(response.headers.authorization);
    //   response.data.token = response.headers.authorization;
    // }

    return response.data;
  },
  error => {
    if (error.response.status === 401) {
      store
        .dispatch("auth/logout")
        .then(() => {
          router.push("/access");
        })
        .catch(err => {
          p;
          console.log(err);
        });
    }
    return Promise.reject(error);
  }
);

export default service;
