import request from "@/utils/request";
import router, {resetRouter} from "@/routes/router";

const state = {
  user: null,
  roles: [],
  permissions: [],
};

const getters = {
  isLogged: state => !!state.user,
  isSubscribed: state => state.user.hasActiveSubscription,
  roles: state => state.roles,
  permissions: state => state.permissions,
};

const mutations = {
  setUserData(state, userData) {
    state.user = userData;
    state.roles = userData.roles;
    state.permissions = userData.permissions;
    // localStorage.setItem("user", JSON.stringify(userData));
    request.defaults.headers.common.Authorization = `Bearer ${userData.token}`;
  },

  setSubscribeStatus(state, status) {
    state.user.hasActiveSubscription = status;
  },

  clearUserData(state) {
    localStorage.removeItem("user");
    state.user = null;
    state.roles = [];
    state.permissions = [];
    request.defaults.headers.common.Authorization = "";
  },

  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },

  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
};

const actions = {
  login({ commit }, credentials) {
    return request.post("/login", credentials).then(data => {
      commit("setUserData", data);
    });
  },

  register({ commit }, credentials) {
    return request.post("/register", credentials).then(data => {
      commit("setUserData", data);
    });
  },

  logout({ commit, rootState }) {
    return request.get("/logout").then(() => {
      commit("clearUserData");
    });
  },

  subscribe({ commit }, planId) {
    request.post("/subscribe", { plan_id: planId }).then(data => {
      if (data.success === true) {
        commit("setSubscribeStatus", data.success);
      }
    });
  },

  changePassword({ commit }, data) {
    return request.post("/change-password", data);
  },

  // Dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(async resolve => {
      // const token = role + '-token';

      // commit('SET_TOKEN', token);
      // setToken(token);

      // const { roles } = await dispatch('getInfo');

      const roles = [role.name];
      const permissions = role.permissions.map(permission => permission.name);
      commit('SET_ROLES', roles);
      commit('SET_PERMISSIONS', permissions);
      resetRouter();

      // generate accessible routes map based on roles
      const accessRoutes = await store.dispatch('permission/generateRoutes', { roles, permissions });

      // dynamically add accessible routes
      router.addRoutes(accessRoutes);

      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
