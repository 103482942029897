import {default as asyncRoutes} from '@/routes/routes';
import request from "@/utils/request";

const state = {};

const mutations = {

};

const actions = {
  fetchUsers({  commit }) {
    return request.get("/users");
  },

  fetchUser({  commit }, payload) {
    return request.get("/users/"+ payload.id);
  },

  addUser({  commit }, payload) {
    return request.post("/users/", payload);
  },

  updateUser({ commit}, payload) {
    return request.put("users/" + payload.id, payload);
  },

  fetchUserPermissions({  commit }, payload) {
    return request.get("/users/" + payload.id + '/permissions');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
