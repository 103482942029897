var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboards',
          icon: 'ni ni-shop text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', path: '/dashboard' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Links',
          icon: 'ni ni-send text-info',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Nhập link', path: '/dashboard/submit-links' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Danh sách link', path: '/dashboard/archive-links' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Từ khoá',
          icon: 'ni ni-sound-wave text-success',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Dự án', path: '/dashboard/projects' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Content',
          icon: 'ni ni-collection text-primary',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Danh sách articles', path: '/dashboard/articles' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Rewrite article', path: '/dashboard/rewrite' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Danh sách rewrite articles',
            path: '/dashboard/rewrite-articles',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Blog Networks',
          icon: 'ni ni-world-2 text-success',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Danh sách blog', path: '/dashboard/blog-networks' }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Traffic',
          icon: 'ni ni ni-chart-bar-32 text-orange',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Dự án', path: '/dashboard/traffic/projects' }}})],1),_c('sidebar-item',{directives:[{name:"permission",rawName:"v-permission",value:(['view_menu_administrator']),expression:"['view_menu_administrator']"}],attrs:{"link":{
          name: 'Admin',
          icon: 'ni ni ni-settings-gear-65 text-danger',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Link Api', path: '/dashboard/admin/submit-links' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Phân quyền', path: '/dashboard/admin/permissions' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Quản lý tài khoản',
            path: '/dashboard/admin/users',
          }}})],1)],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }