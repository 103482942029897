import request from "@/utils/request";

const state = {
  status: false
};

const getters = {};

const mutations = {
  SET_SUBMIT_LINK_STATUS(state, status) {
    state.status = status;
  },
  SET_LINK_DATA(state, data) {

  }
};

const actions = {
  submitLinks({ commit }, inputs) {
    return request.post("/links/submit", inputs, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(() => {
      commit("SET_SUBMIT_LINK_STATUS", false);
    });
  },

  fetchLinks({ commit }, query) {
    return request.get("/links/fetch", {
      params: query
    })
  },

  fetchSubmitLinkApis({ commit }) {
    return request.get("/link_apis");
  },

  updateSubmitLinkApis({ commit}, payload) {
    return request.put("link_apis/" + payload.id, payload);
  },

  deleteLink({ commit }, id) {
    return request.delete("/links/delete/" + id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
