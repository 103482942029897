/* eslint-disable no-unused-vars */
import request from "@/utils/request";

const state = {};
const getters = {};
const mutations = {};

const actions = {
  fetchArticles({ commit }, query) {
    return request.get("/articles", {
      params: query,
    });
  },
  addArticle({ commit }, inputs) {
    return request.post("/articles", inputs);
  },
  getArticle({ commit }, id) {
    return request.get("/articles/" + id);
  },
  getApiProgress({ commit }, id) {
    return request.get("/articles/get-api-progress/" + id);
  },
  saveArticleContent({ commit }, id) {
    return request.get("/articles/save-article-content/" + id);
  },
  deleteArticle({ commit }, id) {
    return request.delete("/articles/" + id);
  },
  rewriteArticle({ commit }, inputs) {
    return request.post("/articles/rewrite", inputs);
  },
  saveRewriteArticle({ commit }, inputs) {
    return request.post("/articles/save-rewrite-article", inputs);
  },
  getRewriteArticle({ commit }, id) {
    return request.get("/articles/rewrite/" + id);
  },
  fetchRewriteArticles({ commit }, query) {
    return request.get("/articles/rewrite/list", {
      params: query,
    });
  },
  deleteRewriteArticle({ commit }, id) {
    return request.delete("/articles/rewrite/" + id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
