import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AdminLayout from "@/views/Layout/AdminLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";
import ContentLayout from "@/views/Layout/Content.vue";
// GeneralViews
import NotFound from "@/views/GeneralViews/NotFoundPage.vue";

// Calendar
const Calendar = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Calendar/Calendar.vue");
// Charts
const Charts = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Charts.vue");

// Components Pages
const Buttons = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Buttons.vue");
const Cards = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Cards.vue");
const GridSystem = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/GridSystem.vue"
  );
const Notifications = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/Notifications.vue"
  );
const Icons = () =>
  import(/* webpackChunkName: "components" */ "@/views/Components/Icons.vue");
const Typography = () =>
  import(
    /* webpackChunkName: "components" */ "@/views/Components/Typography.vue"
  );

// Dashboard Pages
const Dashboard = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue");
const AlternativeDashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "@/views/Dashboard/AlternativeDashboard.vue"
  );
const Widgets = () =>
  import(/* webpackChunkName: "dashboard" */ "@/views/Widgets.vue");

// Forms Pages
const FormElements = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormElements.vue");
const FormComponents = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormComponents.vue");
const FormValidation = () =>
  import(/* webpackChunkName: "forms" */ "@/views/Forms/FormValidation.vue");

// Maps Pages
const GoogleMaps = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Maps/GoogleMaps.vue");
const VectorMaps = () =>
  import(/* webpackChunkName: "extra" */ "@/views/Maps/VectorMaps.vue");

// Pages
const User = () =>
  import(/* webpackChunkName: "Pages" */ "@/views/Pages/UserProfile.vue");
const Pricing = () =>
  import(/* webpackChunkName: "Pages" */ "@/views/Pages/Pricing.vue");
const TimeLine = () =>
  import(/* webpackChunkName: "Pages" */ "@/views/Pages/TimeLinePage.vue");
const Login = () =>
  import(/* webpackChunkName: "Pages" */ "@/views/Pages/Login.vue");
const Home = () =>
  import(/* webpackChunkName: "Pages" */ "@/views/Pages/Home.vue");
// const Register = () =>
//   import(/* webpackChunkName: "Pages" */ "@/views/Pages/Register.vue");
const Lock = () =>
  import(/* webpackChunkName: "Pages" */ "@/views/Pages/Lock.vue");
const PricingPlan = () =>
  import(/* webpackChunkName: "Pages" */ "@/views/Pages/PricingPlan.vue");
const SubmitLinks = () =>
  import(/* webpackChunkName: "Pages" */ "@/views/Pages/SubmitLinks.vue");
const ArchiveLinks = () =>
  import(/* webpackChunkName: "Pages" */ "@/views/Pages/ArchiveLinks.vue");
const Chart = () => import("@/views/Pages/Chart.vue");
const ManageSubmitLinkApi = () =>
  import("@/views/Pages/Admin/ManageSubmitLinkApi.vue");
const Permissions = () => import("@/views/Pages/Admin/Permissions.vue");
const Users = () => import("@/views/Pages/Admin/Users.vue");
const AddTerm = () => import("@/views/Pages/Terms/AddTerm.vue");
const AddTermProject = () => import("@/views/Pages/Terms/AddTermProject.vue");
const ViewProject = () => import("@/views/Pages/Terms/ViewProject.vue");
const ListProject = () => import("@/views/Pages/Terms/ListProject.vue");
const ViewTerm = () => import("@/views/Pages/Terms/ViewTerm.vue");
const ListTerm = () => import("@/views/Pages/Terms/ListTerm.vue");
const Profile = () => import("@/views/Pages/UserProfile/Profile.vue");
const AddTrafficProject = () =>
  import("@/views/Pages/Traffic/AddTrafficProject.vue");
const ViewTrafficProject = () =>
  import("@/views/Pages/Traffic/ViewTrafficProject.vue");
const ListTrafficProject = () =>
  import("@/views/Pages/Traffic/ListTrafficProject.vue");
const ListArticle = () => import("@/views/Pages/Articles/ListArticle.vue");
const AddArticle = () => import("@/views/Pages/Articles/AddArticle.vue");
const ArticleDetail = () => import("@/views/Pages/Articles/ArticleDetail.vue");
const RewriteArticle = () =>
  import("@/views/Pages/Articles/RewriteArticle.vue");
const ListRewriteArticle = () =>
  import("@/views/Pages/Articles/ListRewriteArticle.vue");
const ListBlogNetworks = () =>
  import("@/views/Pages/BlogNetworks/ListBlogNetworks.vue");
const BlogNetworkDetail = () =>
  import("@/views/Pages/BlogNetworks/BlogNetworkDetail.vue");
const AddBlogNetwork = () =>
  import("@/views/Pages/BlogNetworks/AddBlogNetwork.vue");
const ViewShareProject = () =>
  import("@/views/Pages/Terms/ViewShareProject.vue");
const ViewShareKeyword = () =>
  import("@/views/Pages/Terms/ViewShareKeyword.vue");

// TableList Pages
const RegularTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/RegularTables.vue");
const SortableTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/SortableTables.vue");
const PaginatedTables = () =>
  import(/* webpackChunkName: "tables" */ "@/views/Tables/PaginatedTables.vue");
let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons,
    },
    {
      path: "cards",
      name: "Cards",
      component: Cards,
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem,
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications,
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons,
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography,
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Form elements",
      component: FormElements,
    },
    {
      path: "components",
      name: "Form components",
      component: FormComponents,
    },
    {
      path: "validation",
      name: "Form validation",
      component: FormValidation,
    },
  ],
};

let tablesMenu = {
  path: "/tables",
  component: DashboardLayout,
  redirect: "/table/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Tables",
      component: RegularTables,
    },
    {
      path: "sortable",
      name: "Sortable",
      component: SortableTables,
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      component: PaginatedTables,
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      component: GoogleMaps,
    },
    {
      path: "vector",
      name: "Vector Map",
      component: VectorMaps,
    },
  ],
};

let pagesMenu = {
  path: "/Pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/Pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User,
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine,
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "",
      name: "Home",
      redirect: "/dashboard",
      component: Home,
      meta: {
        noBodyBackground: true,
      },
    },
    {
      path: "/access",
      name: "Login",
      component: Login,
    },
    // {
    //   path: "/register",
    //   name: "Register",
    //   component: Register
    // },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
    { path: "*", component: NotFound },
  ],
};

const routes = [
  // {
  //   path: '/',
  //   redirect: '/home',
  // },
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  {
    path: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "alternative",
        name: "Alternative",
        component: AlternativeDashboard,
        meta: {
          navbarType: "light",
        },
      },
      {
        path: "calendar",
        name: "Calendar",
        component: Calendar,
      },
      {
        path: "charts",
        name: "Charts",
        component: Charts,
      },
      {
        path: "widgets",
        name: "Widgets",
        component: Widgets,
      },
      {
        path: "pricing",
        name: "PricingPlan",
        component: PricingPlan,
      },
      {
        path: "submit-links",
        name: "SubmitLinks",
        component: SubmitLinks,
      },
      {
        path: "projects",
        name: "ListProject",
        component: ListProject,
      },
      {
        path: "projects/:id",
        name: "ViewProject",
        component: ViewProject,
      },
      {
        path: "create-project",
        name: "AddTermProject",
        component: AddTermProject,
      },
      {
        path: "projects/:project_id/submit-keyword",
        name: "AddTerm",
        component: AddTerm,
      },
      {
        path: "terms",
        name: "ListTerm",
        component: ListTerm,
      },
      {
        path: "serp-view/:id",
        name: "ViewTerm",
        component: ViewTerm,
      },
      {
        path: "archive-links",
        name: "ArchiveLinks",
        component: ArchiveLinks,
      },
      {
        path: "index-chart",
        name: "IndexChart",
        component: Chart,
      },
      {
        path: "traffic/projects",
        name: "ListTrafficProject",
        component: ListTrafficProject,
      },
      {
        path: "traffic/projects/create",
        name: "AddTrafficProject",
        component: AddTrafficProject,
      },
      {
        path: "traffic/projects/:id",
        name: "ViewTrafficProject",
        component: ViewTrafficProject,
      },
      {
        path: "articles",
        name: "ListArticle",
        component: ListArticle,
      },
      {
        path: "articles/:id",
        name: "ArticleDetail",
        component: ArticleDetail,
      },
      {
        path: "create-article",
        name: "AddArticle",
        component: AddArticle,
      },
      {
        path: "rewrite-articles",
        name: "ListRewriteArticle",
        component: ListRewriteArticle,
      },
      {
        path: "rewrite",
        name: "RewriteArticle",
        component: RewriteArticle,
      },
      {
        path: "rewrite/:id",
        name: "RewriteArticle",
        component: RewriteArticle,
      },
      {
        path: "blog-networks",
        name: "ListBlogNetworks",
        component: ListBlogNetworks,
      },
      {
        path: "blog-networks/add",
        name: "AddBlogNetwork",
        component: AddBlogNetwork,
      },
      {
        path: "blog-networks/:id",
        name: "BlogNetworkDetail",
        component: BlogNetworkDetail,
      },
      {
        path: "admin",
        component: AdminLayout,
        children: [
          {
            path: "submit-links",
            name: "ManageSubmitLinkApi",
            component: ManageSubmitLinkApi,
          },
          {
            path: "permissions",
            name: "Permissions",
            component: Permissions,
          },
          {
            path: "users",
            name: "Users",
            component: Users,
          },
        ],
      },
    ],
  },
  {
    path: "/user",
    component: DashboardLayout,
    children: [
      {
        path: "profile",
        name: "UserProfile",
        component: Profile,
      },
    ],
  },
  {
    path: "/keyword-project/:id",
    component: ContentLayout,
    children: [
      {
        path: "/",
        name: "ViewShareProject",
        component: ViewShareProject,
      },
    ],
  },
  {
    path: "/keyword-view/:id",
    component: ContentLayout,
    children: [
      {
        path: "/",
        name: "ViewShareKeyword",
        component: ViewShareKeyword,
      },
    ],
  },
  authPages,
];

export default routes;
