/* eslint-disable no-unused-vars */
import request from "@/utils/request";

const state = {
  status: false,
};

const getters = {};

const mutations = {
  SET_SUBMIT_LINK_STATUS(state, status) {
    state.status = status;
  },
  SET_LINK_DATA(state, data) {},
};

const actions = {
  // addTerm({ commit }, inputs) {
  //   return request.post("/terms", inputs);
  // },
  addTerm({ commit }, inputs) {
    return request.post("/keywords", inputs);
  },
  getLocations({ commit }, query) {
    return request.get("/terms/locations", {
      params: {
        query: query,
      },
    });
  },
  getLanguages({ commit }) {
    return request.get("/terms/languages");
  },
  fetchTerms({ commit }, query) {
    return request.get("/terms", {
      params: query,
    });
  },
  // fetchTerm({ commit }, data) {
  //   return request.get("/terms/" + data.id, {
  //     params: data.query
  //   });
  // },
  fetchTerm({ commit }, data) {
    return request.get("/keywords/" + data.id, {
      params: data.query,
    });
  },
  // updateTerm({ commit }, payload) {
  //   return request.put("terms/" + payload.id, payload);
  // },
  updateTerm({ commit }, payload) {
    return request.put("keywords/" + payload.id, payload);
  },
  fetchProjects({ commit }, query) {
    return request.get("/term_projects", {
      params: query,
    });
  },
  fetchProject({ commit }, data) {
    return request.get("/term_projects/" + data.id, {
      params: data.query,
    });
  },
  // fetTermByProject({ commit }, data) {
  //   return request.get("/term_projects/" + data.id + "/terms", {
  //     params: data.query
  //   });
  // },
  fetchTermByProject({ commit }, data) {
    data.query.term_project_id = data.id;
    // Replace api endpoint "/term_projects/" + data.id + "/terms" to "keywords"
    return request.get("/keywords", {
      params: data.query,
    });
  },
  addProject({ commit }, inputs) {
    return request.post("/term_projects", inputs);
  },
  deleteProject({ commit }, id) {
    return request.delete("/term_projects/" + id);
  },
  shareProject({ commit }, id) {
    return request.get("/term_projects/" + id + "/share");
  },
  fetchShareProject({ commit }, data) {
    return request.get("/share-projects/" + data.id, {
      params: data.query,
    });
  },
  fetchTermByShareProject({ commit }, data) {
    data.query.term_project_id = data.id;
    return request.get("/share-project-keywords/", {
      params: data.query,
    });
  },
  fetchShareKeyword({ commit }, data) {
    return request.get("/share-keywords/" + data.id, {
      params: data.query,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
