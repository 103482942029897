import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserEdit,
  faEdit,
  faFilter,
  faCalendarAlt,
  faTrashAlt,
  faSave,
  faShareAltSquare,
} from "@fortawesome/free-solid-svg-icons";

library.add(faUserEdit);
library.add(faEdit);
library.add(faFilter);
library.add(faCalendarAlt);
library.add(faTrashAlt);
library.add(faSave);
library.add(faShareAltSquare);

export default library;
