<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboards',
            icon: 'ni ni-shop text-primary',
          }"
        >
          <sidebar-item
            :link="{ name: 'Dashboard', path: '/dashboard' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Links',
            icon: 'ni ni-send text-info',
          }"
        >
          <sidebar-item
            :link="{ name: 'Nhập link', path: '/dashboard/submit-links' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Danh sách link', path: '/dashboard/archive-links' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Từ khoá',
            icon: 'ni ni-sound-wave text-success',
          }"
        >
          <sidebar-item
            :link="{ name: 'Dự án', path: '/dashboard/projects' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Content',
            icon: 'ni ni-collection text-primary',
          }"
        >
          <sidebar-item
            :link="{ name: 'Danh sách articles', path: '/dashboard/articles' }"
          ></sidebar-item>

          <sidebar-item
            :link="{ name: 'Rewrite article', path: '/dashboard/rewrite' }"
          ></sidebar-item>

          <sidebar-item
            :link="{
              name: 'Danh sách rewrite articles',
              path: '/dashboard/rewrite-articles',
            }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Blog Networks',
            icon: 'ni ni-world-2 text-success',
          }"
        >
          <sidebar-item
            :link="{ name: 'Danh sách blog', path: '/dashboard/blog-networks' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Traffic',
            icon: 'ni ni ni-chart-bar-32 text-orange',
          }"
        >
          <sidebar-item
            :link="{ name: 'Dự án', path: '/dashboard/traffic/projects' }"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          v-permission="['view_menu_administrator']"
          :link="{
            name: 'Admin',
            icon: 'ni ni ni-settings-gear-65 text-danger',
          }"
        >
          <sidebar-item
            :link="{ name: 'Link Api', path: '/dashboard/admin/submit-links' }"
          ></sidebar-item>

          <sidebar-item
            :link="{ name: 'Phân quyền', path: '/dashboard/admin/permissions' }"
          ></sidebar-item>

          <sidebar-item
            :link="{
              name: 'Quản lý tài khoản',
              path: '/dashboard/admin/users',
            }"
          ></sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import permission from "@/directives/permission";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import { mapGetters } from "vuex";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  directives: { permission },
  computed: {
    ...mapGetters({
      isSubscribed: "auth/isSubscribed",
      isLogged: "auth/isLogged",
    }),
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();

    if (!this.isLogged) {
      this.$router.push("/access");
    }
  },
};
</script>
<style lang="scss"></style>
