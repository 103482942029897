import Vue from "vue";
import store from "./store";
import axios from "axios";
import DashboardPlugin from "./plugins/dashboard-plugin";
import VuePreloaders from "vue-preloaders";
import HighchartsVue from "highcharts-vue";
import DefaultLoader from "@/components/DefaultLoader";
import Echo from "laravel-echo";
import App from "./App.vue";
// eslint-disable-next-line no-unused-vars
import library from "@/utils/icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import KProgress from "k-progress";

// router setup
import router from "./routes/router";

// Font awesome setup
Vue.component("font-awesome-icon", FontAwesomeIcon);

// Pusher setup
window.Pusher = require("pusher-js");
window.Echo = new Echo({
  broadcaster: "pusher",
  key: "4f0804e2e4883a5a4720",
  // cluster: "ap1",
  // encrypted: true,
  wsHost: "localhost",
  wsPort: 3030,
  forceTLS: false,
  disableStats: true,
});

Vue.config.productionTip = false;
Vue.config.performance = true;

// plugin setup
Vue.use(DashboardPlugin);

Vue.use(HighchartsVue);

Vue.use(VuePreloaders, {
  cssStyle: { position: "fixed", "z-index": 99999 },
  overlayStyle: {
    backgroundColor: "#ecf0ef",
    opacity: 0.5,
  },
  component: DefaultLoader,
});

// K-progress
Vue.component("k-progress", KProgress);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  created() {
    const userInfo = this.$store.state.auth.user;
    if (userInfo) {
      this.$store.commit("auth/setUserData", userInfo);
    }
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(error.response);
        if (error.response.status === 401) {
          return this.$store.dispatch("auth/logout");
        }
        return Promise.reject(error);
      }
    );
  },
  render: (h) => h(App),
});
