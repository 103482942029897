import request from "@/utils/request";

const state = {
  status: false,
  qrCode: null
};

const getters = {
  paymentStatus: state => state.status,
};

const mutations = {
  setPaymentStatus(state, status) {
    state.status = status;
  },
  SET_PAYMENT_QR_CODE(state, code) {
    state.qrCode = code;
  }
};

const actions = {
  async getMomoQRCode({ commit, rootState }, data) {
    return await request.post('/payment/momo/qr-code', data);
  },
  async confirmPaypalPayment({ commit, rootState }, data) {
    return await request.post('/payment/paypal/confirm', data);
  },
  getPlans({commit}) {
    return request.get('/plans');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
