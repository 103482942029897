import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import createLogger from "vuex/dist/logger";
// import the auto exporter
import modules from "./modules";
import getters from './getters';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";
const ls = new SecureLS({ isCompression: false });

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  }
};

const actions = {};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules,
  getters,
  strict: debug,
  plugins: debug
    ? [
        createLogger(),
        createPersistedState({
          storage: {
            getItem: key => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: key => ls.remove(key)
          }
        })
      ]
    : [
        createPersistedState({
          storage: {
            getItem: key => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: key => ls.remove(key)
          }
        })
      ] // set logger only for development
});
