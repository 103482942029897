import request from "@/utils/request";

const state = {
  status: false
};

const getters = {};

const mutations = {
  SET_SUBMIT_LINK_STATUS(state, status) {
    state.status = status;
  },
  SET_LINK_DATA(state, data) {}
};

const actions = {
  getLanguages({ commit }) {
    return request.get("/terms/languages");
  },
  getCountries({ commit }, query) {
    return request.get("/terms/countries");
  },
  fetchProjects({ commit }, query) {
    return request.get("/traffic/projects", {
      params: query
    });
  },
  fetchProject({ commit }, data) {
    return request.get("/traffic/projects/" + data.id, {
      params: data.query
    });
  },
  addProject({ commit }, inputs) {
    return request.post("/traffic/projects", inputs);
  },
  updateProject({ commit }, payload) {
    return request.put("/traffic/projects/" + payload.id, payload);
  },
  deleteProject({ commit }, data) {
    return request.delete("/traffic/projects/" + data.id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
