/* eslint-disable no-unused-vars */
import request from "@/utils/request";

const state = {};
const getters = {};
const mutations = {};

const actions = {
  fetchBlogNetworks({ commit }, query) {
    return request.get("/blog-networks", {
      params: query,
    });
  },
  fetchNetworks({ commit }) {
    return request.get("/blog-networks/networks");
  },
  getBlogNetworksDetail({ commit }, id) {
    return request.get("/blog-networks/" + id);
  },
  deleteBlogNetworkService({ commit }, id) {
    return request.delete("/blog-networks/" + id);
  },
  addBlogNetworks({ commit }, inputs) {
    return request.post("/blog-networks", inputs);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
